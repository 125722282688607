<template>
  <div class="container-enhancer flex">
    <div class="header flex justify-between items-center">
      <back-btn @back="handleBack" />
    </div>

    <div class="flex justify-center items-start" :style="{ marginTop: minClient ? '24px' : '49px' }">
      <!-- Before -->
      <div class="enhancer-item" style="margin-right: 60px;">
        <h2 :style="{ marginBottom: minClient ? '10px' : '24px' }">Original</h2>
        <image-board :width="currentContainerSize.width">
          <img crossOrigin :src="resultImageSrc.originUrl" />
        </image-board>
      </div>
      <!-- After -->
      <div class="enhancer-item">
        <h2 :style="{ marginBottom: minClient ? '10px' : '24px' }">Result</h2>
        <div class="position-box" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
          <image-board :width="currentContainerSize.width">
            <img crossOrigin :src="resultImageSrc.resultUrl" />
            <div v-if="isLoading" class="loading-mask"></div>
          </image-board>
          <div class="buttons-container" v-if="showBtns">
            <custom-btn :customStyle="customStyle" :showPrefixIcon="false" @click="handleDownload"
              activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)">
              <div>
                Download HD
              </div>
            </custom-btn>
          </div>
        </div>
        <div class="common-rate-abs">
          <common-rate @changeRate="handleMattingChangeRate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUnmounted, watch, reactive, computed } from "vue";
import BackBtn from "@/components/boolv-ui/back-btn";
import ImageBoard from "@/components/boolv-ui/image-board";
import CommonRate from "@/components/common-rate.vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { getEnhancerResult } from "@/api/models/image-enhancer.js";
import { getImageUrlByPreviewId, downloadImageFromUrl } from "@/utils/index.js";
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { storeToRefs } from 'pinia'
import {
  MessageSuccess,
  MessageError,
  MessageLoading,
  destroy,
} from "@/components/boolv-ui/Message/index.js";

import { useSideStore, useViewStore } from '@/store/index.js'
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)
const emits = defineEmits(["back"]);
const props = defineProps({
  imageList: Object,
});

const previewId = ref("");
const isLoading = ref(true);
const currentImageList = ref([]);
const hasTaskProgress = ref(false)
const resultImageSrc = ref({
  ...props.imageList[0],
  resultUrl: props.imageList[0].originUrl,
});

const customStyle = reactive({
  width: '180px',
  height: '56px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
  'margin-top': '10px'
})

const currentContainerSize = computed(() => {
  if (sideStore.minClient) {
    return {
      width: '522px',
      height: '522px'
    }
  } else {
    return {
      width: '560px',
      height: '560px'
    }
  }
})

watch(() => props.imageList, async () => {
  resultImageSrc.value = {
    ...props.imageList[0],
    resultUrl: props.imageList[0].originUrl,
  }
  await judgeUploadProgress(props.imageList)
  handleEnhancer();
}, { deep: true })

// 判断是否有未上传完的图
const judgeUploadProgress = (imageList) => {
  return new Promise((resolve, reject) => {
    let item = imageList.find(item => !item.s3Uri)
    if (item) {
      reject()
    } else {
      resolve()
    }
  })
}

const handleImageStartLoading = () => {
  sideStore.showGlobalMask = true;
  MessageLoading(
    "Processing, it will take a while",
    0,
    "",
    null,
    false,
    closeRequest
  );
}


onMounted(async () => {
  handleImageStartLoading()
  // viewStore.setMinViewWidth('1400px')
});

const handleEnhancer = async () => {
  // 请求之后将状态设置为true只请求一次，因为结果会影响原数组，file数组深拷贝比较麻烦，所以用变量控制
  if (hasTaskProgress.value) {
    return
  }
  hasTaskProgress.value = true
  const params = {
    inputS3Uri: resultImageSrc.value.s3Uri,
    renderId: 1,
  };
  isLoading.value = true;
  try {
    const { code, data } = await getEnhancerResult(params);
    if (code === 0) {
      destroy();
      isLoading.value = false;
      sideStore.showGlobalMask = false;
      MessageSuccess("Processing completed");
      resultImageSrc.value.resultUrl = data.outputUrl;
      //埋点
      currentImageList.value.push(data.outputS3Uri);
      data.source = resultImageSrc.value.originUrl;
      previewId.value = data.previewId;
    }
  } catch (error) {
    destroy();
    sideStore.showGlobalMask = false;
    isLoading.value = false;
    // MessageError("error");
    console.log(error);
  }
}


const closeRequest = () => {
  //TODO
  destroy();
};

const handleBack = () => {
  emits("back");
};

const width = ref("560px");
const showBtns = ref(false);

const handleMouseEnter = () => {
  if (isLoading.value) {
    return;
  }
  showBtns.value = true;
};

const handleMouseLeave = () => {
  showBtns.value = false;
};

//download
const storeCurrentImage = () => {

  sessionStorage.setItem('currentImageId', JSON.stringify(currentImageList.value));
}
const handleDownload = async () => {
  if (!previewId.value) {
    return;
  }

  storeCurrentImage();
  const url = await getImageUrlByPreviewId(previewId.value);

  if (url) {
    eventTracking("booltool_page_download", { tool_name: "enhancer", is_batch: false, is_success: true, })
    await downloadImageFromUrl(url);
  } else {
    eventTracking("booltool_page_download", { tool_name: "enhancer", is_batch: false, is_success: false, })
  }

};

const handleMattingChangeRate = (item) => {
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  eventTracking('booltool_page_feedback', { tool_name: 'enhancer', emoji_str: emoji[item.id - 1] });
};

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.container-enhancer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 36px 0px;
  min-width: 1400px;
}

.header {
  height: 46px;
  display: flex;
  align-items: flex-end;
}

.enhancer-item {
  position: relative;

  h2 {
    // padding-bottom: 24px;
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
  }
}

.common-rate-abs {}

.loading-mask {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  opacity: 0.5;
  pointer-events: all;
}

:global(.download-button.ant-btn-primary) {
  width: 180px !important;
  height: 56px !important;
}
</style>
